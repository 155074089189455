import router from "@/router/index";
import {emitter, reloadSideRouterKey} from "@/common/event/EventBus";

let routerList = [];

function dynamicAddRouter(data) {
    if (data === null || data === undefined) {
        return;
    }
    if (!(data instanceof Array)) {
        return;
    }
    data.forEach(item => {
        if (item.children) {
            item.children.forEach(child => {
                let params
                if (child.params) {
                    params = JSON.parse(child.params)
                }

                router.addRoute("AdminView", {
                    path: child.path,
                    name: child.name_en,
                    component: () => dynamicRouteComponent(import(`@/views/${child.component}.vue`), child.name_en),
                    props: params,
                    meta: {
                        requiredAuth: true,
                    }
                })
            })
        }
    })

    routerList = data;
    sessionStorage.setItem("cacheRouterList", JSON.stringify(data));
    emitter.emit(reloadSideRouterKey, "");
}

function getRouterList() {
    if (routerList.length < 1) {
        let cacheRouterList = sessionStorage.getItem("cacheRouterList");
        if (cacheRouterList) {
            routerList = JSON.parse(cacheRouterList);
            if (routerList.length > 0) {
                dynamicAddRouter(routerList);
            }
        }
    }
    return routerList
}

/**
 * 异步加载组件，修改组件名字
 * @param _import
 * @param routeName
 * @returns {*}
 */
function dynamicRouteComponent(_import, routeName) {
    return _import.then((comp) => {
        if (comp && comp.default) {
            return {...comp.default, name: routeName}
        }
        return comp
    })
}


export {dynamicAddRouter, getRouterList}
