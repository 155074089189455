<template>
  <router-view>
  </router-view>
</template>

<script>

import {onMounted} from "vue";

export default {
  name: 'App',
  setup() {
    onMounted(() => {

      document.body.style.setProperty('--el-color-primary', '#257590');
      document.body.style.setProperty('--el-color-primary-light-9', '#409EFF');
      document.body.style.setProperty('--el-color-primary-light-3', '#409EFF');
    })
  }
}

// 解决ERROR ResizeObserver loop completed with undelivered notifications. 问题的
const debounce = (fn, delay) => {
  let timer = null;

  return function () {
    let context = this;
    let args = arguments;

    clearTimeout(timer);
    timer = setTimeout(function () {
      fn.apply(context, args);
    }, delay);
  }
}

// 解决ERROR ResizeObserver loop completed with undelivered notifications.问题的
const _ResizeObserver = window.ResizeObserver;
window.ResizeObserver = class ResizeObserver extends _ResizeObserver {
  constructor(callback) {
    callback = debounce(callback, 16);
    super(callback);
  }
}
</script>

<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  width: 100%;
  height: 100%;
}

/* 在组件的<style>中 */
.el-header {
  --el-header-padding: 0 !important;
}


#nav {
  margin: 0;
  padding: 0;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
